// export const NOTIFICATION_TYPES = [
// 	"OUTGOING_SCRIPT_SMS",
// 	"OUTGOING_SCRIPT_MMS",
// 	"OUTGOING_SMS",
// 	"ACCEPT_ANSWER",
// 	"BOT_RESPONSE",
// 	"INCOMING_SMS",
// ];

// TODO: This is a TEST! Trying out enums
export class NOTIFICATION_TYPES {
  // Private Fields
  static #_OUTGOING_SCRIPT_SMS = 0;
  static #_OUTGOING_SCRIPT_MMS = 1;
  static #_OUTGOING_SMS = 2;
  static #_ACCEPT_ANSWER = 3;
  static #_BOT_RESPONSE = 4;
  static #_INCOMING_SMS = 5;
  static #_SWITCHED_CONVO = 6;
  static #_SKIPPED_CONVO = 7;

  // Accessors for "get" functions only (no "set" functions)
  static get OUTGOING_SCRIPT_SMS() { return this.#_OUTGOING_SCRIPT_SMS; }
  static get OUTGOING_SCRIPT_MMS() { return this.#_OUTGOING_SCRIPT_MMS; }
  static get OUTGOING_SMS() { return this.#_OUTGOING_SMS; }
  static get ACCEPT_ANSWER() { return this.#_ACCEPT_ANSWER; }
  static get BOT_RESPONSE() { return this.#_BOT_RESPONSE; }
  static get INCOMING_SMS() { return this.#_INCOMING_SMS; }
  static get SWITCHED_CONVO() { return this.#_SWITCHED_CONVO; }
  static get SKIPPED_CONVO() { return this.#_SKIPPED_CONVO; }
}