export const TOTAL_PHONE = 100;

export const ACTIONABLE_THRESHOLD = 5; // request new numbers when there are this many agent-actionable conversations left

export const TERMINATION_DELAY = 1500;

export const BOT_RESPONSE_DELAY = 1500;

export const NGROK_ENABLED = process.env.REACT_APP_NGROK_ENABLED;

export const DISABLE_NO_BOT_RESPONSE = process.env.REACT_APP_DISABLE_NO_BOT_RESPONSE;

export const SERVER_URLROOT = NGROK_ENABLED ? 'https://[your-tunnel-domain]' : process.env.REACT_APP_S160_API_BASEURL;

export const REQUEST_OBJECT = {
  url: SERVER_URLROOT,
  type: "POST",
  dataType: "json",
  contentType: "application/json",
  crossDomain: true,
};

export const REATTEMPT_PREFIX = "Just making sure you got this: ";

export const BOT_RESPONSE_PHRASES = [
  "1",
  "2",
  "3",
  "4",
];

export const TERMINATING_QUESTION_TYPES = [
  "terminating",
  "closing",
  "panel_close",
];
export const TERMINATING_QUESTION_TYPES_STATUS_MAP = {
  terminating: { method: "terminated", disposition: "auto terminated" },
  closing: { method: "closed", disposition: "closing question" },
  panel_close: { method: "panel", disposition: "closing panel" },
};
