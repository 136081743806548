export function deepCopy(a) {
  return JSON.parse(JSON.stringify(a));
}

export function filterSample(s, misc) {
  Object.keys(misc).forEach(function(ky) {
    var re = new RegExp("{" + ky + "}", "g");
    s = s.replace(re, misc[ky]);
  });
  return s;
}

export function setTimeoutAndStoreId(handler, time, storageName) {
  let prevId = localStorage.getItem(storageName);
  if (prevId) {
    window.clearTimeout(parseInt(prevId));
  }

  const id = window.setTimeout(handler, time);
  localStorage.setItem(storageName, id);

  return id;
}

export function setIntervalAndStoreId(handler, time, storageName) {
  let prevId = localStorage.getItem(storageName);
  if (prevId) {
    window.clearInterval(parseInt(prevId));
  }

  const id = window.setInterval(handler, time);
  localStorage.setItem(storageName, id);

  return id;
}

/**
 * Overrides setInterval. Saves all interval ids to localstorage.
 * @param {*} handler
 * @param {*} timeout
 * @param {*} args
 */
export function setIntervalCustom(handler, timeout, args) {
  // Create interval
  const id = window.setInterval(handler, timeout, args);

  // Get list of existing intervals
  const existringIntervals = localStorage.getItem("intervals");
  let intervalList = JSON.parse(existringIntervals);

  if (!intervalList) {
    intervalList = []
  }

  // Add new interval to list
  intervalList.push(id);
  localStorage.setItem("intervals", JSON.stringify(intervalList));

  return id;
}

/**
 * Overrides
 * @param {*} id
 */
export function clearIntervalCustom(id) {
  // Clear interval
  window.clearInterval(id);

  const existringIntervals = localStorage.getItem("intervals");
  let intervalList = JSON.parse(existringIntervals);

  if (!intervalList) {return;}

  intervalList = intervalList.filter(item => item === id);
  localStorage.setItem("intervals", JSON.stringify(intervalList));
}

/**
 * Removes all existing intervals
 */
export function clearAllIntervals() {
  const existringIntervals = localStorage.getItem("intervals");
  let intervalList = JSON.parse(existringIntervals);

  if (!intervalList) {return;}

  intervalList.forEach(id => {
    window.clearInterval(id);
  });

  localStorage.removeItem("intervals");
}

/**
 * Overrides setTimeout. Saves all timeout ids to localstorage.
 * @param {*} handler
 * @param {*} timeout
 * @param {*} args
 */
export function setTimeoutCustom(handler, timeout, args) {
  // Create timeout
  const id = window.setTimeout(handler, timeout, args);

  // Get list of existing timeout
  const existringTimeouts = localStorage.getItem("timeouts");
  let timeoutList = JSON.parse(existringTimeouts);

  if (!timeoutList) {
    timeoutList = [];
  }

  // Add new interval to list
  timeoutList.push(id);
  localStorage.setItem("timeouts", JSON.stringify(timeoutList));

  return id;
}

/**
 * Overrides
 * @param {*} id
 */
export function clearTimeoutCustom(id) {
  // Clear interval
  window.clearTimeout(id);

  // get existing timeouts
  const existringTimeouts = localStorage.getItem("timeouts");
  let timeoutList = JSON.parse(existringTimeouts);

  if (!timeoutList) {return;}

  timeoutList = timeoutList.filter(item => item === id);
  localStorage.setItem("timeouts", JSON.stringify(timeoutList));
}

/**
 * Removes all existing intervals
 */
export function clearAllTimeouts() {
  const existringTimeouts = localStorage.getItem("timeouts");
  let timeoutList = JSON.parse(existringTimeouts);

  if (!timeoutList) {return;}

  timeoutList.forEach(id => {
    window.clearTimeout(id);
  });

  localStorage.removeItem("timeouts");
}

export function requestErrorHandler(jqXHR, textStatus, errorThrown) {
  console.log(jqXHR, textStatus, errorThrown);
}

/**
 * Get rid of things like +, which tend to show up, 
 * by removing all non-digits, and remove a leading 1, which sometimes does, too.
 * @param {string} phone The raw phone number
 */
 export function cleanPhoneNumber(phone) {
  phone = phone.replace(/\D/g, "");
  if (phone.charAt(0) === "1") {
    phone = phone.substr(1);
  }

  return phone
 }
 
export function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
  document.cookie = name + "=" + (value || "") + expires + "; path=/dashboard/";  // why are we doing this?
}

export function removeCookie(name) {
  document.cookie = name + "=; expires=" + new Date(0).toUTCString() + "; path=/;";
  document.cookie = name + "=; expires=" + new Date(0).toUTCString() + "; path=/dashboard/;";
}

export function removeCookieList(list) {
  //console.log("Remove Cookies List");
  
  list.forEach(item => {
    removeCookie(item);
  });
}

export function minuteToMilliseconds(minutes) {
  return minutes*60*1000;
}

export function millisecondToMinutes(millis) {
  return millis/1000/60;
}

export function millisecondToSeconds(millis) {
  return millis/1000;
}


/**
 * Returns true if the sms being sent is a folloup to a question. If it's a script question returns false
 * @param {Array} conversation The conversation array of objects 
 * @param {string} currentQuestionId 
 */
export function isFollowup (conversation, currentQuestionId) {
  const convo = conversation.filter(item => item.who === "sender");
  if (convo.length > 0 && currentQuestionId === convo[convo.length - 1].currentscriptid) {
    return true;
  }
  return false;
}

export function isActionable(paraconversation) {
  if (paraconversation.terminating) {
    return false
  } else {
    const conversation = paraconversation.conversation
    return conversation.length === 0
        || conversation[conversation.length - 1].who === "recipient"
  }
}

/**
 * Takes the recipient list object and returns an array of ordered phone str
 * @param {*} phoneObject 
 * @returns {array} The sorted phones
 */
export const sortPhones = (phoneObject) => {
  return (
    Object.keys(phoneObject)
      // Order by uuid
      .sort((a, b) => {
        const ua = phoneObject[a].phoneorder;
        const ub = phoneObject[b].phoneorder;

        // Based on article
        // https://alligator.io/js/array-sort-strings/
        return ua > ub ? -1 : ub > ua ? 1 : 0;
      })
      // order by convo length and recipient
      .sort((a, b) => {
        const aConvo = phoneObject[a].conversation;
        const bConvo = phoneObject[b].conversation;

        // Exit early  for skipped
        if (phoneObject[a].skipped || phoneObject[a].loading || phoneObject[a].terminating
        ) {
          return 1;
        } else if (phoneObject[b].skipped || phoneObject[b].loading || phoneObject[b].terminating
        ) {
          return -1;
        }

        let aWho =
          !aConvo.length ||
          aConvo[aConvo.length - 1].who === "recipient" ||
          aConvo[aConvo.length - 1].who === "accepted"
            ? 1
            : 0;
        let bWho =
          !bConvo.length ||
          bConvo[bConvo.length - 1].who === "recipient" ||
          bConvo[bConvo.length - 1].who === "accepted"
            ? 1
            : 0;

        // We can use 0 because date comparison uses the time since 1970
        let aDate = aConvo.length
          ? new Date(aConvo[aConvo.length - 1].date).getTime()
          : 0;
        let bDate = bConvo.length
          ? new Date(bConvo[bConvo.length - 1].date).getTime()
          : 0;

        // Add 1 for reattempt to move that up in the list
        aWho += phoneObject[a].reattempt ? 1 : 0;
        bWho += phoneObject[b].reattempt ? 1 : 0;

        // The sort result for convo length.
        const convo_length = bConvo.length - aConvo.length;
        let amount = bDate - aDate;

        // The sort result for who's turn it is.
        let order = bWho - aWho;
        const current_amessage = aConvo[aConvo.length - 1];
        const current_bmessage = bConvo[bConvo.length - 1];
        const aMessage_what =
          aConvo.length > 0 ? current_amessage.what.toLowerCase() : false;
        const bMessage_what =
          bConvo.length > 0 ? current_bmessage.what.toLowerCase() : false;

        if (order !== 0) {
          return order;
        } else if (convo_length < 0) {
          return -1;
        } else if (order === 0 && current_amessage && bMessage_what) {
          if (
            current_amessage.type === "intro" &&
            current_amessage.who === "recipient" &&
            (aMessage_what.includes("yes") || aMessage_what.includes(1)) &&
            !bMessage_what.includes("yes")
          ) {
            return -1;
          }
        }
        
        return amount;
        
      })
  );
};

// Put in util.
// Use this here and in DCMContainer when the fields are blank
export function getCookies() {
  //console.log('getting cookies');
  var h = {};
  document.cookie.split("; ").forEach((cook) => {
    var k = cook.split("=");
    h[k[0]] = k[1];
  });
  if (h.jwt === undefined || h.jwt === "") {
    h.jwt = "crap";
  }
  if (h.userid === undefined || h.userid === "") {
    h.userid = "crap";
  }
  if (h.campaignid === undefined) {
    h.campaignid = "-1";
  }
  if (h.permission === undefined) {
    h.permission = "crap";
  }
  return h;
}

export const replaceMiscVarsWithValues = (text, variablesObject) => {
  let result = text;
  Object.keys(variablesObject).forEach((variableName) => {
    const re = new RegExp("{" + variableName + "}", "g");
    result = result.replace(re, variablesObject[variableName]);
  });
  return result;
};