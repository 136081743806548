import React, { useEffect, useState } from "react";
import { reduxConnectedComponent } from "../../util/reduxConnectedHOC";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faForward,
	faPaperPlane,
	faCheckSquare,
	faRobot,
	faCheck,
	faCircleExclamation,
	faEye,
	faEyeSlash,
	faRectangleList,
	faRectangleXmark,
	faX,
} from "@fortawesome/free-solid-svg-icons";

import { FeedEventType } from "../../redux/feed/constants";

const feedEventMap = [
	{icon: faPaperPlane ,class: "outgoing-script-sms"}, // OUTGOING_SCRIPT_SMS
	{icon: faPaperPlane ,class: "outgoing-script-mms"}, // OUTGOING_SCRIPT_MMS
	{icon: faPaperPlane ,class: "outgoing-sms"}, // OUTGOING_SMS
	{icon: faCheckSquare ,class: "accept-answer"}, // ACCEPT_ANSWER
	{icon: faRobot ,class: "bot-response"}, // BOT_RESPONSE
	{icon: faPaperPlane ,class: "incoming-sms", flip: true}, // INCOMING_SMS
	{icon: faForward ,class: "skipped-convo"}, // SKIPPED_CONVO
	{icon: faForward ,class: "switched-convo"}, // SWITCHED_CONVO
	{icon: faCircleExclamation ,class: "terminated"}, // AUTO_TERMINATED
	{icon: faCheck ,class: "accept-answer"}, // CLOSED
	{icon: faRectangleList ,class: "accept-answer"}, // NEW_NUMBERS_LOADED
	{icon: faRectangleXmark ,class: "terminated"}, // NO_NEW_NUMBERS
	{icon: faRectangleList ,class: "accept-answer"}, // ONGOING_RECORDS_LOADED
	{icon: faRectangleList,class: "terminated"}, // PERMANENT_OPTOUT
	{icon: faX,class: "terminated"}, // TERMINATED
	{icon: faRectangleList,class: "skipped-convo",} // RELEASED_INACTIVE_NUMBERS
];

/**
 * @param {object} props
 * @param {Array<import("../../redux/feed/actions").FeedEvent>} props.items
 * @param {function} props.mockNotification
 * @returns
 */
function TexterEventFeed({ items = [], mockNotification }) {
	const [showFeed, setShowFeed] = useState(true);

	return (
		<>
			<h1
				className={`title is-5 m-2 event-feed-${
					showFeed ? "open" : "closed"
				}`}
			>
				Event Feed
			</h1>
			<div className="column is-full p-0 m-0 is-flex fullheight">
				<div className="event-feed">
					<div
						className={`event-feed-${
							!showFeed ? "closed" : "open"
						}`}
					>
						{items
							.sort((a, b) => a.id - b.id)
							.map((item, index) => {
								let info = feedEventMap[item.feedEventType];
								
								return (
									<div
										key={index}
										className={"item "}
										title={item.message}
									>
										<span
											className={
												feedEventMap[
													item.feedEventType
												].class
											}
										>
											<FontAwesomeIcon
												icon={info.icon}
												flip={info.flip ? "horizontal" : null}
											/>
										</span>{" "}
										<b>{item.message}</b>
										{item.recipientId &&
											` ID: ${item.recipientId}`}
									</div>
								);
							})}
					</div>
					<span className="has-text-right">
						<button
							className="button is-rounded is-short"
							title={showFeed ? "Hide Feed" : "Show Feed"}
							onClick={() => setShowFeed(!showFeed)}
						>
							<FontAwesomeIcon
								icon={showFeed ? faEyeSlash : faEye}
							/>
						</button>
					</span>
				</div>
			</div>
		</>
	);
}

export default reduxConnectedComponent(TexterEventFeed, (state) => ({
	items: state.eventFeed.queue,
}));
