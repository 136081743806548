import * as actionTypes from "./types";

const initialState = {
	queue: [],
};

/**
 *
 * @param {object} state
 * @param {Array<{id: number}>} state.queue
 * @param {object} action
 * @returns {object} New state
 */
export default function feedEventsReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.ADD_FEED_EVENT:
			// console.log("Adding feedEvent", action);
			// We don't need to create random IDs for feedEvents,
			//we can just use the UTC time now and add 1 for each new feedEvent. This way we can sort them by time withough having to worry about collisions
			return {
				...state,
				queue: [...state.queue, action.feedEvent],
			};
		case actionTypes.UPDATE_FEED_EVENT:
			return {
				...state,
				queue: state.queue.map((event) =>
					event.id === action.feedEvent.id
						? { ...event, ...action.feedEvent }
						: event
				),
			};
		case actionTypes.REMOVE_FEED_EVENT:
			return {
				...state,
				queue: state.queue.filter((event) => event.id !== action.id),
			};
		case actionTypes.REMOVE_TAIL:
			return {
				...state,
				queue: state.queue.slice(0, -15),
			};
		default:
			return state;
	}
}
