import { fetchRequest } from "../../middleware/fetchMiddleware";
import { addFeedEvent, getFeedEvent } from "../feed/actions";
import { FeedEventType } from "../feed/constants";
import { RELEASE_INACTIVE_PREFIX } from "./types";
/**
 * Post request to remove recipients from list that haven't had activity in, more than N minutes (20 by default)
 * @returns {Promise} The request promise
 */
export const releaseInactiveRecipients = () => (dispatch, getState) => {
  const state = getState();
  return dispatch(
    fetchRequest(
      RELEASE_INACTIVE_PREFIX,
      "POST",
      "/texter/releaseInactive",
      {
        campaignid: state.auth.campaignid,
      }
    )
  ).then((data) => {
    if (data && data.data) {
      const len = Object.keys(data.data).length;
      if (len !== 0) {
        // feedEvent. loaded more numbers (count)
        const e = getFeedEvent(
          FeedEventType.NEW_NUMBERS_LOADED,
          "",
        );
        e.message += ` (${len})`;
        dispatch(addFeedEvent(e));
      }
    }
    return data;
  });
};
