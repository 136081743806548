import React, { Component } from "react";
import PromptModalContainer from "../modals/PromptModal.container";
import { BottomPanelScreen } from "../../screens/BottomPanel/BottomPanel.screen";
import { deepCopy, replaceMiscVarsWithValues } from "../../util/helper";
import MediaContainer from "../../containers/MediaContainer";
import ConversationHead from "../Conversation/ConversationHead";
import { evaluateConditionals, ScriptLogicError } from "../../util/scriptLogic";
import { getConversationNextScript } from "../../util/scriptLogicNext";
import { REATTEMPT_PREFIX, TERMINATING_QUESTION_TYPES_STATUS_MAP } from "../../util/constants";

class ConversationComponent extends Component {

	render() {
		const {
			selectedAnswerIdx,
			selectAnswer,
			conversation,
			misc,
			selectedPhone,
			currentscriptid,
			prompts,
			justsentscript,
			disableAutoselect,
			phoneChanged,
			reattempt,
			campaignId,
			jwt,
		} = this.props;

		// NOT 100% on this one here...
		// currentscriptid ==> the script ID of the last sent question. In the transcript already.
		// pendingID 			 ==> The script ID that we are about to send or have sent if we are accepting. Meaning the text is loaded.
		// nextScriptId		 ==> The script ID of the next question, after the one we are handling.

		let adaptedScript;
		try {
			adaptedScript = this.getCurrentQuestion(currentscriptid);
		} catch (e) {
			if (e instanceof ScriptLogicError) {
				const errInfo = {
					position: e.position,
					statement: e.statement,
					parsedCondition: e.parsedCondition,
					errorType: e.errorType,
					phone: this.props.selectedCampaignPhone,
				};
				console.log(errInfo);
				// this.props.addTexterAppEvent(EVENT_TYPES.ERROR, e.message, "init_conditional", errInfo);
			} else {
				console.groupCollapsed(e);
				console.error(e);
				console.log(
					"Error getting current question\n",
					"currentscriptid", currentscriptid,
					"\nscript", this.props.script,
					"\nconversation", conversation,
					"\nmisc", misc,
					"\nselectedPhone", selectedPhone,
					"\nprompts", prompts,
					"\njustsentscript", justsentscript,
					"\ndisableAutoselect", disableAutoselect,
					"\nphoneChanged", phoneChanged,
					"\nreattempt", reattempt,
					"\ncampaignId", campaignId,
					"\nselectedAnswerIdx", selectedAnswerIdx,
					"\nselectAnswer", selectAnswer,
				);
				console.groupEnd();

				// this.props.addTexterAppEvent(EVENT_TYPES.ERROR, e.message, "init_conditional");
			}
		}

		const pendingQuestionId = adaptedScript.id;
		const hasQuestionBeenSent = conversation.length > 0 ? conversation.some((item) => {
			// console.log("Checking if question has been sent", item, pendingQuestionId);
			const a = (item.currentscriptid === pendingQuestionId && item.who === "sender")
			if (a) {
				console.log("Question has been sent:", item);
			}
			return a
		}) : false;

		// NOTE This is where the answers come from. So we have to pass it 
		// around as an argument
		const adaptedAnswers = hasQuestionBeenSent ? adaptedScript.answers.map((answer) => {
			answer.text = replaceMiscVarsWithValues(answer.text, misc);
			return answer;
		}) : [];

		// console.log("Adapted Answers", adaptedAnswers);
		// console.log("Selected Answer Idx", selectedAnswerIdx);
		// console.log("Render - adaptedScript", adaptedScript);

		// TBD but this should be all we need for autoselect
		console.log("Disable Autoselect", disableAutoselect);
		const selectedAnswerIdxFinal =
			adaptedScript.type === "text" ? 0
			: disableAutoselect || selectedAnswerIdx !== -1 ? selectedAnswerIdx
			: this.autoSelect(adaptedAnswers);

		// TODO Make sure to use selectedAnswerIdxFinal to generate the next question text so
		// 			we can display next with autoselect as well
		let nextQuestionText = "";
		let nextQuestionItem = null;
		let selectedAnswer = null;

		if (!hasQuestionBeenSent) {
			nextQuestionText = replaceMiscVarsWithValues(adaptedScript.question, misc);
		}
		else {
			if (conversation[conversation.length - 1].who !== "sender" && selectedAnswerIdxFinal !== -1) {

				// The value `-1` is used because the built-in JS `findIndex` returns `-1` when it doesn't find anything, which is used in autoSelect
				selectedAnswer = this.getSelectedAnswer(pendingQuestionId, adaptedScript.type, adaptedAnswers, selectedAnswerIdxFinal);

				nextQuestionItem = this.getNextQuestion(pendingQuestionId, selectedAnswer);
				// `nextQuestionText`  should be null if the selected answer results in termination, such as "permanent opt out" or declining the intro message
				nextQuestionText = selectedAnswer.acceptedAnswer.trim().toLocaleLowerCase() === "permanent opt out"
					? null
					: nextQuestionItem.question.length > 0 ? nextQuestionItem.question : null;
			}

			if (reattempt) {
				nextQuestionText = `${REATTEMPT_PREFIX}${this.getNextQuestion(pendingQuestionId, selectedAnswer, reattempt).question}`;
			}
		}

		console.group("Rendering");
		console.log("selectedAnswerIdxFinal", selectedAnswerIdxFinal);
		console.log("adaptedScript", adaptedScript);
		console.log("convo", conversation);
		console.log("adaptedAnswers", adaptedAnswers);
		console.log("selectedPhone", selectedPhone);
		console.log("nextQuestionItem", nextQuestionItem);
		console.log("nextQuestionText", nextQuestionText);
		console.log("reattempt", reattempt);
		console.log("hasQuestionBeenSent", hasQuestionBeenSent);
		console.groupEnd();

		const onlyAllowSend = !hasQuestionBeenSent || reattempt || (nextQuestionText && nextQuestionItem === null);

		return (
			<div className="dcm-sender-conversation pane panel vertical">
				<MediaContainer
					campaignid={campaignId}
					jwt={jwt}
				>
					<ConversationHead
						selectedCampaignCurrentPhone={selectedPhone}
						selectedAnswerId={selectedAnswerIdxFinal}
						answerList={adaptedAnswers}
						toggleRadio={selectAnswer}
						{...this.props.headerProps}
					/>
				</MediaContainer>
				<BottomPanelScreen
					selectedAnswerId={selectedAnswerIdxFinal}
					justsentscript={justsentscript}
					inputPlaceholder="Please select an answer"
					questionText={nextQuestionText}
					prompts={prompts}
					variables={misc}
					onlyAllowSend={onlyAllowSend}
					disabled={selectedAnswerIdxFinal === -1}
					phoneChanged={phoneChanged}

					// Callbacks
					sendAction={this.generateSendAction(selectedPhone, pendingQuestionId)}
					acceptAnswerAction={this.generateSendAndAcceptAction(
						selectedPhone,
						pendingQuestionId,
						selectedAnswer,
						conversation,
						adaptedAnswers,
						nextQuestionItem,
					)}
					resetSelectedAnswer={this.props.resetSelectedAnswer}
				/>
			</div>
		);
	}

	/**
	 * Get's the script question associated with the given scriptID
	 * @param {string} scriptId Pass scriptid in so that we can get questions other than the current one
	 * @returns {Object} The question blob
	 */
	getCurrentQuestion(scriptId, currentConversation = null) {
		const {
			initialConditionals,
			misc,
			defaultFirstQuestionId,
			script,
		} = this.props;
		const conversation = currentConversation || this.props.conversation;
		let nextQuestionId;

		if (scriptId) {
			nextQuestionId = scriptId;
		} else if (
			// previously evaluateInitialConditionals
			// If no scriptid is passed then use the initConditionals to get the first one.
			(nextQuestionId = evaluateConditionals(
				initialConditionals,
				misc,
				conversation, 
				defaultFirstQuestionId
			))
		) {
			// initialConds assigned in condition to prevent double-evaluateInitialConditionals
		} else {
			console.warn(
				"Could not find a saved spot in the script, or an initial conditional. Restarting to first script item."
			);
			// If that fails, just use the first one in the script
			nextQuestionId = script[0].id;
		}
		
		// console.log("Evaluating Initial Conditionals", script, defaultFirstQuestionId, nextQuestionId);
		return deepCopy(script.find((q) => q.id === nextQuestionId));
	}

	/**
	 * Check for an answer to autoselect and get it's idx
	 * @param {Array<Object>} answers the array of answer data
	 * @returns {number} The index of the auto-selected answer
	 */
	autoSelect(answers) {
		// NOTE: careful of falsy checks with the selectedAnswerId!!!
		// It's an index so it can be 0 which returns false
		const { conversation } = this.props;
		const convo_length = conversation.length;
		const current_convo_item = conversation[convo_length - 1];

		if (!current_convo_item || current_convo_item.who !== "recipient") {
			return -1;
		}
		console.log("AutoSelect", current_convo_item);
		const current_message = current_convo_item.what
			.toLocaleLowerCase()
			.trim();
		// -1 means not selected
		return answers.findIndex(
			(answer) =>
				current_message === answer.text.toLocaleLowerCase().trim() ||
				current_message ===
					answer.value.toString().toLocaleLowerCase().trim()
		);
	}

	/**
	 * @typedef {Object} SelectedAnswer
	 * @property {string} value The value of the selected answer (mostly numbers, but as strings E.G. '1')
	 * @property {string} id The id of the question this answer value is answering
	 * @property {string} type what type of question are we answering
	 * 						type in ("text", "closed")
	 * @property {string} acceptedAnswer The accepted text
	 */

	/**
	 *
	 * @param {*} pendingQuestionId
	 * @param {*} questionType
	 * @param {Array<Object>} answers The list of answer data for the question
	 * @param {number} selectedAnswerIdx answer idx
	 * @returns {SelectedAnswer} Answer blob
	 */
	getSelectedAnswer(pendingQuestionId, questionType, answers, selectedAnswerIdx) {
		const { conversation } = this.props;
		// Figure out the correct answer.
		let acceptedAnswer = "ACCEPTED";
		let acceptedValue = "NONE";

		// console.log("getSeletedAnswer", answers, selectedAnswerIdx);

		// If it's closed, take the last response. This should be made nicer?
		if (questionType === "text") {
			acceptedAnswer = conversation[conversation.length - 1].what;
		} else if (answers.length > 0) {
			console.log("Selected Answer Idx", selectedAnswerIdx);
			let selectedAnswer = answers[selectedAnswerIdx];
			acceptedAnswer = selectedAnswer.text;
			acceptedValue = selectedAnswer.value;
		}

		return {
			value: acceptedValue,
			id: pendingQuestionId,
			type: questionType,
			acceptedAnswer,
		};
	}

	/**
	 * 
	 * @param {string} pendingID 
	 * @param {SelectedAnswer} selectedAnswer 
	 * @returns 
	 */
	getNextQuestion(pendingID, selectedAnswer, isReattempt = false) {
		const {
			script,
			conversation,
			misc,
		} = this.props;

		if (!selectedAnswer && !isReattempt) {
			console.warn("No selected answer to get next question text");
			return { question: "" };
		}

		if (isReattempt) {
			const currentQuestion = this.getCurrentQuestion(pendingID);
			currentQuestion.question = replaceMiscVarsWithValues(
				currentQuestion.question,
				misc
			);
			return currentQuestion;
		}

		// Create a blob as if we had accepted the answer
		const item = {
			who: "accepted",
			what: selectedAnswer.acceptedAnswer,
			date: Date(),
			currentscriptid: pendingID,
			// Making this explicit for clarity
			value: selectedAnswer.value,
			// id: selectedAnswer.id, // NEeded?
			type: selectedAnswer.type,
			acceptedAnswer: selectedAnswer.acceptedAnswer,
		};

		// Get the next id
		const updatedTranscript = [...conversation, item];
		// TODO this function is meant for redux state, so we can't really use it here...
		const nextScriptId = getConversationNextScript(
			script,
			misc,
			updatedTranscript,
			item.currentscriptid
		);

		// Get the next question and set it to the preview
		const nextQuestion = this.getCurrentQuestion(nextScriptId);

		nextQuestion.question = replaceMiscVarsWithValues(
			nextQuestion.question,
			misc
		);
		return nextQuestion;
	}

	/**
	 * Sends a message to the recipient
	 * @param {number} selectedAnswerIdx The index of the selected answer
	 * @param {string} pendingQuestionId The id of the current question
	 * @returns {Promise<boolean>} True if the conversation should continue to send, false if the conversation will be terminated
	 * @throws {ScriptLogicError} If the script logic fails
	*/
	generateSendAction = (phone, questionId) => {
		return (message) => {
			console.log("Sending Message", phone, message, questionId);
			return this.props.updateConversationSender(
				phone,
				message,
				questionId,
		)
		.catch((error) => {
			console.warn(`Failed sending message to "${phone}:\n`, error);
		});
		}
	}

	/**
	 * Accepts the selected answer and sends the next question to the recipient
	 * @param {number} phone
	 * @param {number} questionId
	 * @param {Object} selectedAnswer
	 * @param {Array<Object>} conversation
	 * @param {Array<Object>} adaptedAnswers
	 * @param {string} nextQuestionItem
	 * @returns {Function<string>} The action to send the message and accept the answer
	 */
	generateSendAndAcceptAction = (phone, questionId, selectedAnswer, conversation, adaptedAnswers, nextQuestionItem) => {
		return async () => {
			this.props.resetSelectedAnswer();
			const adaptedScriptQuestion = this.getCurrentQuestion(questionId);

			let acceptedAnswer = "ACCEPTED";
			let acceptedValue = "NONE";

			const questionType = adaptedScriptQuestion.type;

			if (questionType === "text") {
				acceptedAnswer = conversation[conversation.length - 1].what;
			} else if (adaptedAnswers.length > 0) {
				acceptedAnswer = selectedAnswer.acceptedAnswer;
				acceptedValue = selectedAnswer.value;
			}
			console.log("Accepted Answer to send", acceptedAnswer, acceptedValue, questionId, selectedAnswer);

			try {
				const nextQuestionId = await this.props.updateConversationAccepted(
					phone,
					acceptedAnswer,
					questionId,
					{
						acceptedAnswer,
						value: acceptedValue,
						id: adaptedScriptQuestion.id,
						type: questionType,
					}
				)

				console.log(`Accepted Answer for ${phone} | QUESTION ID "${adaptedScriptQuestion.id}`,  acceptedAnswer);

				if (acceptedAnswer.trim().toLocaleLowerCase() === "permanent opt out") {
					console.log("Triggering Permanent Opt Out");
					return this.props.addToPermanentOptOutAndTerminateConversation(phone);
				}

				if (!nextQuestionItem.question && nextQuestionItem.type in TERMINATING_QUESTION_TYPES_STATUS_MAP) {
					return this.props.timedFinishConversation(phone, TERMINATING_QUESTION_TYPES_STATUS_MAP[nextQuestionItem.type].method, TERMINATING_QUESTION_TYPES_STATUS_MAP[nextQuestionItem.type].disposition);
				}

				

				console.log("Continuing Conversation:", phone ,". Next ID:", nextQuestionId);
				return this.props.updateConversationSender(
					phone,
					nextQuestionItem.question,
					nextQuestionId,
				);
			} catch (error) {
				console.warn("Error accepting and sending:\n", error);
			}
		}
	}
}


export default ConversationComponent;