import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";

import { reduxConnectedComponent } from "../../util/reduxConnectedHOC";

// Should be 60 but probably better to have a small buffer so the
// indicator doesn't blink every minute.
const MAX_SECONDS_BETWEEN_LOGS = 65;

/**
 * Checks the agent time log last minute and shows the agent if the
 * time logger is not functioning properly.
 * @param {object} props
 * @param {Date | null} props.lastMinuteLogged
 * @param {Date} props.lastAction The datetime of the last action taken by the agent ( send / accept )
 */
function AgentTimeLogChecker({ lastMinuteLogged, lastAction }) {
  const [lastCheckedAction, setChecked] = useState(new Date(0));
  const [active, setActive] = useState(false);

  // No logged minute yet, so assume it's active
  // or already checked this action
  if (lastMinuteLogged === null) {
    return null;
  }

  if (lastMinuteLogged !== null && lastAction !== lastCheckedAction) {
    setChecked(lastAction);
    // If milliseconds are over the max difference it's broken
    setActive(
      (new Date() - lastMinuteLogged) / 1000 <= MAX_SECONDS_BETWEEN_LOGS
    );
  }

  // There's an issue, so render a warning to refresh
  if (!active) {
    return (
      <div className="notification box">
        <p>
          <IconComponent
            icon={faExclamationTriangle}
            color="danger"
          />
          <span className="has-text-danger">WARNING </span>
          There is an issue connecting to our servers... Please
          refresh your browser to fix this!{" "}
          <IconComponent icon={faRefresh} color="success" />
        </p>
      </div>
    );
  }

  // No issue, render nothing
  return null;
}

/**
 *
 * @param {object} props
 * @param {string} props.icon
 * @param {("success" | "danger" | "warning" | "")} props.color
 * @returns
 */
function IconComponent({ icon, color }) {
  return <FontAwesomeIcon icon={icon} className={`has-text-${color}`} />;
}

const AgentTimeLogCheckerContainer = reduxConnectedComponent(
  AgentTimeLogChecker,
  function (state) {
    return {
      ...state.agent.agentTimeLogChecker,
    };
  },
  {}
);

export default AgentTimeLogCheckerContainer;
