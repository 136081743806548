// export const NOTIFICATION_TYPES = [
// 	"OUTGOING_SCRIPT_SMS",
// 	"OUTGOING_SCRIPT_MMS",
// 	"OUTGOING_SMS",
// 	"ACCEPT_ANSWER",
// 	"BOT_RESPONSE",
// 	"INCOMING_SMS",
// ];

// TODO: This is a TEST! Trying out enums
export class FeedEventType {
  // Private Fields
  static #_OUTGOING_SCRIPT_SMS = 0;
  static #_OUTGOING_SCRIPT_MMS = 1;
  static #_OUTGOING_SMS = 2;
  static #_ACCEPT_ANSWER = 3;
  static #_BOT_RESPONSE = 4;
  static #_INCOMING_SMS = 5;
  static #_SKIPPED_CONVO = 6;
  static #_SWITCHED_CONVO = 7;  
  static #_AUTO_TERMINATED = 8;
  static #_CLOSED = 9;
  static #_NEW_NUMBERS_LOADED = 10;
  static #_NO_NEW_NUMBERS = 11;
  static #_ONGOING_RECORDS_LOADED = 12;
  static #_PERMANENT_OPTOUT = 13;
  static #_TERMINATED = 14;
  static #_RELEASED_INACTIVE_NUMBERS = 15;
  
  // Accessors for "get" functions only (no "set" functions)
  static get OUTGOING_SCRIPT_SMS() { return this.#_OUTGOING_SCRIPT_SMS; }
  static get OUTGOING_SCRIPT_MMS() { return this.#_OUTGOING_SCRIPT_MMS; }
  static get OUTGOING_SMS() { return this.#_OUTGOING_SMS; }
  static get ACCEPT_ANSWER() { return this.#_ACCEPT_ANSWER; }
  static get BOT_RESPONSE() { return this.#_BOT_RESPONSE; }
  static get INCOMING_SMS() { return this.#_INCOMING_SMS; }
  static get SKIPPED_CONVO() { return this.#_SKIPPED_CONVO; }
  static get SWITCHED_CONVO() { return this.#_SWITCHED_CONVO; }  
  static get AUTO_TERMINATED() { return this.#_AUTO_TERMINATED; }
  static get CLOSED() { return this.#_CLOSED; }
  static get NEW_NUMBERS_LOADED() { return this.#_NEW_NUMBERS_LOADED; }
  static get NO_NEW_NUMBERS() { return this.#_NO_NEW_NUMBERS; }
  static get ONGOING_RECORDS_LOADED() { return this.#_ONGOING_RECORDS_LOADED; }
  static get PERMANENT_OPTOUT() { return this.#_PERMANENT_OPTOUT; }
  static get TERMINATED() { return this.#_TERMINATED; }
  static get RELEASED_INACTIVE_NUMBERS() { return this.#_RELEASED_INACTIVE_NUMBERS; }
  
}

// Returns a msg template for each type of event
export const FEED_TYPE_MSG_MAP = [
  "sent script SMS",
  "sent script MMS",
  "sent SMS",
  "accepted answer",
  "bot response received",
  "received SMS",
  "skipped conversation",
  "switched conversation",
  "conversation auto terminated",
  "completed survey",
  "new records loaded",
  "all new records have been assigned",
  "ongoing records loaded",
  "permanent opt-out",
  "conversation terminated",
  "inactive records released",
];