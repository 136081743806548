import { combineReducers } from "redux";
import { loadStateReducer } from "../../util/reusableReducers";
import { AGENT_TIME_LOG_PREFIX, AGENT_STATISTICS_PREFIX, AGENT_TIME_LOG_ACTIVE } from "./types";
import { CLEAR_DATA } from "../common/types";
import { APPEND_TO_CONVERSATION_PREFIX, SET_CONVO_SKIPPED } from "../recipients/types";

const agentTimeLog = loadStateReducer({
  [AGENT_TIME_LOG_PREFIX + "_IS_LOADING"]: "loading",
  [AGENT_TIME_LOG_PREFIX + "_HAS_ERROR"]: "error",
  [AGENT_TIME_LOG_PREFIX + "_POST_SUCCESS"]: "success",
});

const agentStatistics = loadStateReducer({
  [AGENT_STATISTICS_PREFIX + "_IS_LOADING"]: "loading",
  [AGENT_STATISTICS_PREFIX + "_HAS_ERROR"]: "error",
  [AGENT_STATISTICS_PREFIX + "_POST_SUCCESS"]: "success",
});
const initState = {};

// TODo: This is the result of shitty backend data packing!!!
const tempProcessing = (data) => {
  if (!data) {
    return {
      outboundMessageCount: 0,
      minuteCount: 0,
      lastUpdated: new Date(),
    };
  }
  const { count, minutes } = data;
  return {
    outboundMessageCount: count[1],
    minuteCount: minutes[0],
    lastUpdated: new Date(),
  };
};

const processed = (state = initState, action) => {
  switch (action.type) {
    case AGENT_STATISTICS_PREFIX + "_POST_SUCCESS":
      return tempProcessing(action.data);
    case CLEAR_DATA:
      return initState;
    default:
      return state;
  }
};

/**
 * @typedef {object} CheckerState
 * @property {Date | null} lastMinuteLogged
 * @property {Date | null} previousMinuteLogged
 * @property {boolean} active
 * 
 */

const initStateChecker = {
  lastMinuteLogged: new Date(),
  previousMinuteLogged: null,
  active: true,
};

/**
 * Reducer for checking if the agentTimeLog interval is still working
 * @param {CheckerState} state 
 * @param {*} action 
 * @returns 
 */
function agentTimeLogChecker(state = initStateChecker, action) {
  switch (action.type) {
    // Use the time at the beginning of the request to check
    case AGENT_TIME_LOG_PREFIX + "_POST_SUCCESS":
      return {
        ...state,
        lastMinuteLogged: new Date(),
        lastAction: new Date(),
        previousMinuteLogged: state.lastMinuteLogged,
      };
    case SET_CONVO_SKIPPED:
    case APPEND_TO_CONVERSATION_PREFIX + "_IS_LOADING":
      return {
        ...state,
        // TODO should I throttle this to the nearest second?
        lastAction: new Date(),
      };
    default:
      return state;
  }
}

export default combineReducers({ agentTimeLog, agentStatistics, processed, agentTimeLogChecker });
